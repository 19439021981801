import React , { Component }from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'  ;
import { history } from './_helpers/history';
import cookie from 'react-cookies';

//components
import Home from './_components/home';
import AllBooks from './_components/all_books';
import BookDetails from './_components/book_details';
import BookPreview from './_components/book_preview';
import Cart from './_components/cart';
import AboutUs from './_components/about_us';
import PrivacyPolicy from './_components/privacy_policy';
import TermsAndConditions from './_components/termsAndConditions';
import FAQ from './_components/FAQ';
import Login from './_components/Login';
import CustomerReview from './_components/CustomerReview';
import detailsEidBook from './_components/detailsEidBook';
import Media from './_components/media';


class App extends Component {
  constructor(props) {
        super(props);
        history.listen((location, action) => {
            console.log("location has been changed");
        });
        this.state = {
          userLogin: ''
        }
    }
    componentDidMount() {
	// window.location.href = 'https://www.wishuponmystory.com:444/';
    }
  render() {
    return (
       <Router history={history}>

          <Route exact path="/" component={Home} />  
          <Route path="/home" component={Home} />  
          <Route path="/our-books" component={AllBooks} />  
          <Route path="/book-details" component={BookDetails} />  
          <Route path="/book-preview" component={BookPreview} />  
          <Route path="/cart" component={Cart} /> 
          <Route path="/about-us" component={AboutUs} />  
          <Route path="/privacy-policy" component={PrivacyPolicy} />  
          <Route path="/terms-and-conditions" component={TermsAndConditions} />  
          <Route path="/FAQ" component={FAQ} />  
          <Route path="/enter-password" component={Login} />  
          <Route path="/customer-review" component={CustomerReview} />  
          <Route path="/Details" component={detailsEidBook} /> 
          <Route path="/media" component={Media} /> 
      </Router>  
    );
  }
}

export default App;
